import { combineReducers } from 'redux';

import loading from './loading';
import cart from './cart';
import user from './user';
import suggestedDomains from './suggestedDomains';

export default combineReducers({
  loading,
  cart,
  user,
  suggestedDomains
});