import React from 'react'
import styled from 'styled-components'

import responsiveProps from '../../../utils/responsiveProps';

const Button = styled.button`
  padding: 11px 18px;
  color: #fff;
  font-weight: 700;
  background-color: ${props => props.dark ? '#D32228' : '#9F9D9D'};
  min-width: 180px;
  outline: none;
  border: none;
  cursor: pointer;
  position: relative;
  width: auto;
  top: auto;
  left: auto;
  margin: 0;
  z-index: 1;
  &:hover{
    background-color: #B42227;
  }
  &[disabled]{
    background-color: #CBCBCB;
  }
  
  &.tiny{
    padding: 0;
    color: #DE4547;
    min-width: auto;
    font-weight: 600;
    background-color: transparent;

    ${responsiveProps(['background-color'])}
  }
  
  ${responsiveProps(['width','top','left','margin','z-index'])}
`;

const ButtonProlongationWrap = styled.div`
  display: flex;
  margin-bottom: 20px;
`
const ButtonSwitch = styled.div`
  border-left: 2px solid #fff;
  color: #fff;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.on ? '#D32228' : '#9F9D9D'}
  width: 48px;
`

export function ButtonProlongation(props){
  return (
    <ButtonProlongationWrap>
      <Button onClick={props.clickAction}>Автопродление</Button>
      <ButtonSwitch {...props}>{props.on ? 'On' : 'Off'}</ButtonSwitch>
    </ButtonProlongationWrap>
  )
}

export default Button;