import React from 'react'
import styled, { ThemeProvider, css } from 'styled-components'

import { SvgLinkArrow } from "./LinkIcon";
import { SvgRedirect } from "../SvgIcon";

import {colorRed} from "../../../constants/variables";

import responsiveProps from '../../../utils/responsiveProps';

//LinkBreadcrumb
export const LinkBreadcrumb = styled.a`
  color: ${props => props.active ? '#D32228' : '#444444'};
  text-decoration: none;
  &:hover{
    color: #D32228;
    
    svg{
      path{
        stroke: #D32228;
      }
    }
  }
`;

//Links
const LinkIcon = styled.div`
  width: 30px;
  height: 30px;
  background-color: #9F9D9D;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 10px;
  svg{
    margin-right: 0 !important;
  }
`;

const LinkWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  cursor: pointer;
  &:hover{
    svg path{
      stroke: #D32228;
    }
  }
  svg{
    margin-right: 10px;
  }
  a{ 
    text-decoration: none;
    color: ${props => props.active ? '#D32228' : '#4A4A58'};
    font-weight: 600;
    background-image: linear-gradient(to right, #707070 50%, rgba(255,255,255,0) 0%); 
    background-position: 2px bottom;
    background-size: 10px 1px;
    background-repeat: repeat-x; 
    &:hover{
      border-color: #D32228;
      color: #D32228;
      background-image: linear-gradient(to right, #D32228 50%, rgba(255,255,255,0) 0%);
    }
  }
  
  ${props => props.theme.name === 'themeLinkTiny' && css`
    margin-bottom: 0;
    a{
      font-weight: 400;
      &:hover{
        font-weight: 700;
      }
      
      ${props => {
        if (props.target) return css`
          font-weight: 700;
          color: #D32228;
          background-image: linear-gradient(to right, #D32228 50%, rgba(255,255,255,0) 0%);
        `  
      }
    }
  `}
  
  ${props => props.theme.name === 'themeLinkIcon' && css`
    margin-bottom: 0;
    a{
      background: none;
      font-weight: 400;
    }
    &:hover{
      ${LinkIcon}{
        border: 2px solid #D32228;
        svg{
          path{
            stroke: none;
          }
        } 
      }
      a{
        color: #4A4A58;
        background: none;
      }
    }
  `}
  
  ${props => {
    if(props.common) return css`
      display: inline-block;
      a{
        color: ${colorRed};
        text-decoration: underline;
        background: none;
        &:hover{
          background: none;
        }
      }
    `
  }}
  
  ${responsiveProps(['margin'])}
`;

//THEME
export const themeLinkStandard = {
  name: 'themeLinkStandard'
};
export const themeLinkTiny = {
  name: 'themeLinkTiny'
};
export const themeLinkIcon = {
  name: 'themeLinkIcon'
};


function Link(props){
  let icon;
  switch(props.icon){
    case 'standard':
      icon = <SvgRedirect/>;
      break;
    default: break;
  }
  return (
    <ThemeProvider theme={props.theme}>
      <LinkWrap {...props} className="link">
        {props.view === 'common' && <SvgLinkArrow/>}
        {props.theme.name === 'themeLinkIcon' && <LinkIcon>{icon}</LinkIcon>}
        <a href={props.href} target={props.target}>{props.children}</a>
      </LinkWrap>
    </ThemeProvider>
  )
}

Link.defaultProps = {
  theme: themeLinkStandard
};

export default Link