import React from 'react'
import ReactSelect from 'react-select'

const customStyles = {
  control: () => ({
    width: '360px',
    border: '1px solid #CBCBCB',
    borderRadius: 0,
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  }),
  singleValue: () => ({
    color: '#4A4A58'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '10px 20px 12px'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    paddingRight: '27px',
    color: '#4A4A58',
    // transform: state.isFocused ? 'rotateX(180deg)' : 'rotateX(0deg)'
  }),
  placeholder: () => ({
    color: '#CBCBCB'
  }),
  menu: () => ({
    border: '1px solid #464646',
    position: 'absolute',
    zIndex: 99,
    width: '100%',
    backgroundColor: '#fff'
  }),
  menuList: (provided) => ({
    ...provided,
    padding: '0',
  }),
  option: (provided, state) => ({
    color: '#4A4A58',
    padding: state.isFocused ? '11px 20px 12px 15px' : '11px 20px 12px',
    cursor: 'pointer',
    borderTop: '1px solid #D3D3D3',
    borderLeft: state.isFocused ? '5px solid #D32228' : 'none',
    backgroundColor: state.isFocused ? '#F7F7F7' : '#fff',
    boxSizing: 'border-box'
  }),
};

const customStylesTiny = {
  container: (provided) => ({
    ...provided,
    marginTop: '5px'
  }),
  control: () => ({
    ...customStyles.control(),
    width: '90px',
    border: 'none'
  }),
  singleValue: () => ({
    ...customStyles.singleValue()
  }),
  valueContainer: () => ({
    ...customStyles.valueContainer(),
    padding: '0'
  }),
  indicatorsContainer: () => ({
    alignItems: 'flex-start'
  }),
  indicatorSeparator: () => ({
    ...customStyles.indicatorSeparator()
  }),
  dropdownIndicator: (provided, state) => ({
    display: state.isDisabled ? 'none' : 'block'
  }),
  placeholder: () => ({
    ...customStyles.placeholder()
  }),
  menu: () => ({
    ...customStyles.menu(),
    width: '90px'
  }),
  menuList: (provided) => ({
    ...provided,
    padding: '0',
  }),
  option: (provided, state) => ({
    color: '#4A4A58',
    padding: '5px',
    cursor: 'pointer',
    borderTop: '1px solid #D3D3D3',
    backgroundColor: state.isFocused ? '#F7F7F7' : '#fff',
    boxSizing: 'border-box'
  }),
};

function Select(props){
  return (
    <ReactSelect {...props}
                 styles={props.themeSelect === 'tiny' ? customStylesTiny : customStyles}
                 isSearchable={false}
                 isDisabled={props.disabled}
                 placeholder="Выбрать из списка"
                 onChange={props.changeSelect}
    />
  )
}

export default Select