import { connect } from 'react-redux';
import SuggestedDomains from '../../components/SuggestedDomains';
import actions from '../../actions';

const mapStateToProps = state => ({
  cart: state.cart,
  userDomains: state.user.domains.map(({name}) => name),
  domains: state.suggestedDomains,
  loading: state.loading.suggestedDomains
});

const mapDispatchToProps = dispatch => ({
  addToCart: item => dispatch(actions.addToCart(item)),
  removeFromCart: item => dispatch(actions.removeFromCart(item)),
  searchSuggestedDomains: (query, limit) => dispatch(actions.searchSuggestedDomains(query, limit))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuggestedDomains);
