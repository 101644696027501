import actions from '../../actions';

const suggestedDomains = (state = false, { type }) => {
  switch (type) {
    case actions.SEARCH_SUGGESTED_DOMAINS_REQUEST: return true;
    case actions.SEARCH_SUGGESTED_DOMAINS_FAILURE:
    case actions.SEARCH_SUGGESTED_DOMAINS_SUCCESS:
      return false;
    default: return state;
  }
};

export default suggestedDomains;