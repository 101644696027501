import React, {Fragment} from 'react'
import styled from 'styled-components'
import ReactModal from 'react-modal';

/** Components*/
import {SvgClose} from "../SvgIcon";
import Headline from "../Headline";
import FlexBlock from "../FlexBlock";
import Button from "../Button";
import {Text} from "../Typography";

const ModalClose = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  &:hover{
    svg{
      path{
        stroke: #D32228;
      }
    }
  }
`;

function Popup(props){
  if(document.getElementById('module__tariffs')){
    ReactModal.setAppElement('#module__tariffs');
  }
  if(document.getElementById('module__dns')){
    ReactModal.setAppElement('#module__dns');
  }

  //variables
  let styleModal = {
    overlay: {
      zIndex: 999,
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.75)'
    },
    content: {
      width: '800px',
      top: '200px',
      bottom: 'auto',
      left: 'auto',
      right: 'auto',
      padding: '40px 20px'
    }
  };
  let content = '';

  switch(props.content){
    case 'apply':
      content = <Fragment>
        <Headline text-align="center" display="block">{props.body.head}</Headline>
        <FlexBlock text-align="center" display="block">
          <Text display="block" margin="0 0 20px 0" text-align="center">{props.body.content}</Text>
          <Button onClick={props.func} dark>{props.body.button || 'Применить'}</Button>
        </FlexBlock>
      </Fragment>;
      break;

    case 'text':
      content = <Fragment>
        <Headline text-align="center" display="block">{props.body.head}</Headline>
        <Text display="block">
          {props.body.content}
        </Text>
      </Fragment>;
      break;

    default: break;
  }


  return (
    <ReactModal isOpen={props.showPopup}
                onRequestClose={props.closePopup}
                style={styleModal}
    >
      <ModalClose onClick={props.closePopup}>
        <SvgClose/>
      </ModalClose>

      {content}
    </ReactModal>
  );
}

export default Popup