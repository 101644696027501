import React, {Component} from 'react'
import styled from 'styled-components'
import { prop, compose } from 'ramda';

import { Text } from '../../Typography'
import FlexBlock from '../../FlexBlock'
import Input from '../../Input';
import ActionButton from '../../ActionButton';
import Button from '../../Button'
import { ErrorInput, TipInput } from '../../Error';


const OwnListDnsWrap = styled.div`
  position: relative;
  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: -5px;
    display: block;
    width: 11px;
    height: 11px;
    background-color: #D32228;
    border-radius: 50%;
  }
  &:after{
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    display: block;
    width: 1px;
    height: calc(100% - 28px);
    background-image: linear-gradient(to bottom, #DE4547 50%, rgba(255,255,255,0) 0%);
    background-size: 1px 10px;
    background-repeat: repeat-y;
  }
`

const OwnListDnsWrapInner = styled.div`
  padding-left: 24px;
  top: -5px;
  position: relative;
`

const OwnListDnsBlock = styled.div`
  margin: 25px 0 20px;
`

const OwnListDnsFooter = styled.div`
  display: flex;
  width: 480px;
  
  button{
    z-index: 2;
  }
`

class OwnListDns extends Component {

  state = {
    ownListDns: this.props.listDns
  };

  render() {
    const isDisabled = this.state.ownListDns.some(el => el.error) || this.state.ownListDns.every(el => !el.value);

    return (
      <OwnListDnsWrap>
        <OwnListDnsWrapInner>
          <Text color="#707070">Используются для управления ресурсными записями домена (валидации сертификатов, настройки почты, редиректы, поддоменов, A-записей, MX-записей и др.)</Text>

          <OwnListDnsBlock>
            {this.state.ownListDns.map(el =>
              <FlexBlock margin="0 0 20px 0"
                         width="480px"
                         justify-content="space-between"
                         key={el.name}
              >
                <Text>{el.name}</Text>
                <Input placeholder="ns1.domenus.ru"
                       setValue={this.setDnsValue(el.name)}
                       value={el.value}
                       validationsList={[
                         [str => str.length > 2, 'Менее 3 символов'],
                         [/^[a-z]{2,4}\.[a-z]$/, 'Неверный формат ввода данных']
                       ]}
                       renderError={msg => (
                         <ErrorInput
                           position="0:relative|1000:absolute"
                           left="0:0|1000:calc(100% + 25px)"
                           text-align="0:center|1000:left"
                         >{msg}</ErrorInput>
                       )}
                       tip={<TipInput
                           position="0:relative|1000:absolute"
                           left="0:0|1000:calc(100% + 25px)"
                           text-align="0:center|1000:left"
                         >Пример ввода формы <br /> dd.d </TipInput>}
                />
              </FlexBlock>
            )}
          </OwnListDnsBlock>


          <OwnListDnsFooter>
            <FlexBlock flex="1"></FlexBlock>
            <FlexBlock flex="360px 0 0"
                       flex-wrap="wrap"
            >
              <ActionButton target="addMore" onClick={this.addNewDns}>Добавить ns-сервер</ActionButton>
              <Button width="100%"
                      margin="20px 0 0 0"
                      dark
                      onClick={this.saveList}
                      disabled={isDisabled}
              >Сохранить</Button>
            </FlexBlock>
          </OwnListDnsFooter>
        </OwnListDnsWrapInner>
      </OwnListDnsWrap>
    )
  }

  setDnsValue = name => (val,err) => {
    let changedOwnListDns = this.state.ownListDns.map(el => {
      if(el.name === name){
        return {
          ...el,
          value: val,
          error: err
        };
      }

      return el;
    });

    this.setState({
      ownListDns: changedOwnListDns
    })
  };

  addNewDns = () => {
    const noEmptyFields = this.state.ownListDns.every(el=> prop('value')( el));
    const noErrors = !this.state.ownListDns.some(prop('error'));
    if (noEmptyFields && noErrors) {
      this.setState({
        ownListDns: [
          ...this.state.ownListDns,
          {
            id: this.state.ownListDns.length,
            name: 'DNS ' + (this.state.ownListDns.length+1),
            value: ''
          }
        ]
      });
    }
  };

  saveList = () => {
    let resultListDns = this.state.ownListDns.filter(compose(Boolean, prop('value'))).map(prop('value'));
    this.props.msgNotice('Сохранен собственный список DNS');
    console.log('-----> ', resultListDns);
    //fetch this.state.ownListDns
  }
}

export default OwnListDns