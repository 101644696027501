import actions from '../../actions';

const accessType = (state = '', { type, payload }) => {
  switch (type) {
    case actions.LOAD_USER_DATA_SUCCESS:
      return payload.hasOwnProperty('accessType') ? payload.accessType : state;
    default:
      return state;
  }
};

export default accessType;