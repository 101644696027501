import React from 'react'
import styled from 'styled-components'

import { SvgHeadlineAdmin } from "./HeadlineIcon";
import { SvgArrow } from "../SvgIcon";

/** Utils*/
import responsiveProps from '../../../utils/responsiveProps';

const HEADLINE_SIZE = {
  xl: 30,
  l: 24,
  m: 3,
  s: 4,
  xs: 5
}

const HEADLINE_COLOR = {
  red: '#D32228',
  default: '#555562'
}

const HEADLINE_WEIGHT = {
  normal: 400,
  semibold: 600,
  bold: 700
}

const HeadlineWrap = styled.div`
  color: ${props => props.color ? HEADLINE_COLOR[props.color] : HEADLINE_COLOR['default']};
  font-size: ${props => props.size ? HEADLINE_SIZE[props.size] : HEADLINE_SIZE['l']}px;
  font-weight: ${props => props.weight ? HEADLINE_WEIGHT[props.weight] : 400};
  font-family: 'OpenSans';
  margin-bottom: ${props => props.size === 'xl' ? '10px' : '30px'};
  display: flex;
  align-items: center;
  svg{
    margin-right: 10px;
  }
  
  @media (max-width: 1200px){
    justify-content: ${props => props.view !== 'arrow' ? 'center' : 'flex-start'}
  }
  
  ${responsiveProps(['text-align','display'])}
`;

function Headline(props){
  return (
    <HeadlineWrap {...props}>
      {props.view === 'admin' && <SvgHeadlineAdmin/>}
      {props.view === 'arrow' && <SvgArrow/>}
      {props.children}
    </HeadlineWrap>
  )
}

export default Headline;