import React, {Component} from 'react'
import styled from 'styled-components'
import intersperse from "ramda/src/intersperse";

import Headline from '../Headline'
import { Paragraph } from '../Typography';

import wrapInFragment from '../../../utils/wrapInFragment'

const PeriodWrap = styled.div`
  display: flex;
  margin-bottom: 20px;
  
  @media (max-width: 1200px){
    flex: 100% 0 0;
    justify-content: center;
  }
`;
const PeriodBlock = styled.div`
  border: 1px solid #D8D8D8;
  padding: 19px 33px;
  flex: 30% 0 0;
  &.right-side{
    border-left: none;
  }
`;

export default class Period extends Component{
  render(){
    return(
      <PeriodWrap>
        <PeriodBlock>
          <Headline size='xl' weight="semibold">{this.props.start}</Headline>
          <Paragraph text_align="center" color="rgba(70,70,70,.55)">Дата регистрации</Paragraph>
        </PeriodBlock>
        <PeriodBlock className="right-side">
          <Headline size='xl' weight="semibold">
            {intersperse('.', this.props.finish.split('.').map((el,i) => {
              if(i !== 0){
              return <span className="color-red">{el}</span>
            }
              return el;
            })).map(wrapInFragment)}
          </Headline>
          <Paragraph text_align="center" color="rgba(70,70,70,.55)">Дата окончания</Paragraph>
        </PeriodBlock>
      </PeriodWrap>
    )
  }
}