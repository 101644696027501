import {createGlobalStyle} from 'styled-components';

export function fontFace(name, src, fontWeight = 'normal', fontStyle = 'normal') {
  return `
    @font-face{
      font-family: "${name}";
      src: url(${require('./fonts/' + name + '/' + src + '.woff2')}) format("woff2"), 
           url(${require('./fonts/' + name + '/' + src + '.woff')}) format("woff");
      font-style: ${fontStyle};
      font-weight: ${fontWeight};
    }
  `;
}

export const GlobalStyle = createGlobalStyle`
   ${fontFace('OpenSans', 'OpenSans-Regular', 400, 'normal')}
   ${fontFace('OpenSans', 'OpenSans-Semibold', 600, 'normal')}
   ${fontFace('OpenSans', 'OpenSans-Bold', 700, 'normal')}
  
  //todo убрать, когда весь сайт перепишем
  .component-design{ 
    font: 400 16px 'OpenSans'; 
    color: #4A4A58; 
    *, *::before, *::after { 
      box-sizing: border-box;
    }
    input, button, textarea {
      font-family: inherit;
      font-size: 16px; 
    }
  }
  
  body{
    background-color: #F5F8F9;
  }
  
  .color-{
    &red{
      color: #D32228;
    }
  }
  
  .table-common{
    tr{
      td{
        padding-bottom: 10px;
        &:first-child{
          padding-right: 53px;
        }
      }
      
      &:last-child{
        td{
          padding-bottom: 0;
        }
      }
    }
  }
`;