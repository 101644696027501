import actions from '../../actions';

const domains = (state = [], { type, payload }) => {
  switch (type) {
    case actions.LOAD_USER_DATA_SUCCESS:
      return payload.domains ? payload.domains : state;
    default:
      return state;
  }
};

export default domains;