import React from 'react';

export const SvgBack = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="7.848" height="15.368" viewBox="0 0 7.848 15.368">
      <path id="icon-left" d="M5621.7,231.783l2.264,2.444,3.584,3.87-3.013,3.634-2.835,3.42" transform="translate(5628.545 246.151) rotate(180)" fill="none" stroke="#4a4a58" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    </svg>
  )
};

export const SvgHome = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.961" height="15.959" viewBox="0 0 13.961 15.959">
      <path id="Path_514" data-name="Path 514" d="M5495.259,238.082h3.561v3.769h4.229v-8.815l-6.035-4.856-5.926,4.856v8.815h4.171Z" transform="translate(-5490.088 -226.89)" fill="none" stroke="#444" strokeWidth="2"/>
    </svg>
  )
};

export const SvgSearch = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.621" height="19.621" viewBox="0 0 18.621 19.621">
      <g id="icon-search-off" transform="translate(-372 -584)">
        <g className="svg-hover" data-name="Ellipse 13" transform="translate(372 584)" fill="none" stroke="#9f9d9d" strokeWidth="2">
          <circle cx="7.322" cy="7.322" r="7.322" stroke="none"/>
          <circle cx="7.322" cy="7.322" r="6.322" fill="none"/>
        </g>
        <line className="svg-hover" data-name="Line 36" x2="3" y2="3" transform="translate(385.5 598.5)" fill="none" stroke="#9f9d9d" strokeLinecap="round" strokeWidth="3"/>
      </g>
    </svg>
  )
};

export const SvgDelete = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="6.907" height="6.906" viewBox="0 0 6.907 6.906">
      <g id="icon-delete" transform="translate(-264.986 -544.141)">
        <path id="Path_745" data-name="Path 745" d="M7432.883,639.055l-4.873,4.906" transform="translate(-7162.008 -93.914)" fill="none" stroke="#4a4a58" strokeLinecap="round" strokeWidth="2"/>
        <path id="Path_746" data-name="Path 746" d="M4.873,0,0,4.907" transform="translate(270.892 545.157) rotate(90)" fill="none" stroke="#4a4a58" strokeLinecap="round" strokeWidth="2"/>
      </g>
    </svg>
  )
};

export const SvgClose = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 6.907 6.906">
      <g id="icon-delete" transform="translate(-264.986 -544.141)">
        <path id="Path_745" data-name="Path 745" d="M7432.883,639.055l-4.873,4.906" transform="translate(-7162.008 -93.914)" fill="none" stroke="#4a4a58" strokeLinecap="round" strokeWidth="2"/>
        <path id="Path_746" data-name="Path 746" d="M4.873,0,0,4.907" transform="translate(270.892 545.157) rotate(90)" fill="none" stroke="#4a4a58" strokeLinecap="round" strokeWidth="2"/>
      </g>
    </svg>
  )
};

export const SvgTick = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.81" height="11.57" viewBox="0 0 17.81 11.57">
      <path id="Path_775" data-name="Path 775" d="M3152.734,683.8l6.215,6.186,9.595-9.57" transform="translate(-3151.734 -679.412)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    </svg>
  )
};

export const SvgFilter = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.327" height="17.486" viewBox="0 0 17.327 17.486">
      <g id="icon-filter-off" transform="translate(-373 -613.565)">
        <path className="svg-hover" id="Path_786" data-name="Path 786" d="M10.99,8.263a1.079,1.079,0,0,1,.284.73v7.952a.54.54,0,0,0,.919.385l2.218-2.542c.3-.356.461-.533.461-.885V8.995a1.088,1.088,0,0,1,.284-.73l6.365-6.906A.81.81,0,0,0,20.926,0H5.221a.809.809,0,0,0-.6,1.358Z" transform="translate(368.59 613.565)" fill="#9f9d9d"/>
      </g>
    </svg>
  )
};

export const SvgPlus = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.523" height="14.523" viewBox="0 0 14.523 14.523">
      <g id="Group_317" data-name="Group 317" transform="translate(-488.72 -1131.72)">
        <path id="Path_746" data-name="Path 746" d="M8.241,0,0,8.3" transform="translate(501.829 1139.001) rotate(135)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"/>
        <path id="Path_754" data-name="Path 754" d="M8.241,0,0,8.3" transform="translate(495.962 1144.829) rotate(-135)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"/>
      </g>
    </svg>
  )
};

export const SvgPen = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
      <rect id="Rectangle_205" data-name="Rectangle 205" width="14" height="14" transform="translate(981 530)" fill="#fff"/>
      <rect id="Rectangle_219" data-name="Rectangle 219" width="8.443" height="1.091" transform="translate(5.036 1.32) rotate(44.984)" fill="#666"/>
      <rect id="Rectangle_220" data-name="Rectangle 220" width="8.447" height="1.632" transform="translate(3.751 2.599) rotate(45.008)" fill="#666"/>
      <rect id="Rectangle_221" data-name="Rectangle 221" width="8.44" height="1.094" transform="matrix(0.708, 0.707, -0.707, 0.708, 2.082, 4.272)" fill="#666"/>
      <path id="Path_743" data-name="Path 743" d="M.294,2.859a.743.743,0,0,0-.168.988.742.742,0,0,0,1-.163L3.684,1.119C4,.8,4.079.356,3.852.131a.742.742,0,0,0-1,.163Z" fill="#666"/>
      <path id="Path_744" data-name="Path 744" d="M8.638,11.2a.742.742,0,0,0-.165.993c.23.222,4.951,1.763,4.951,1.763.427.148.652-.077.511-.5,0,0-1.519-4.753-1.741-4.978a.732.732,0,0,0-.993.165Z" fill="#666"/>
    </svg>
  )
};

export const SvgDeleteCart = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
      <rect id="Rectangle_206" data-name="Rectangle 206" width="14" height="14" transform="translate(1005 530)" fill="#fff"/>
      <path id="Path_729" data-name="Path 729" d="M7.716,1.79V0H4.307V1.79H0v2.5H12.023V1.79Zm-.82,0H5.127V.82H6.9V1.79Z" fill="#666"/>
      <path id="Path_730" data-name="Path 730" d="M.835,5.113,1.7,14h8.627l.863-8.887Zm2.816,6.959L3.406,6.845h.821l.245,5.227Zm2.771,0H5.6V6.845h.82Zm1.95,0H7.551L7.8,6.845h.821Z" fill="#666"/>
    </svg>
  )
};

export const SvgRedirect = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12.923" viewBox="0 0 14 12.923">
      <path id="Path_705" data-name="Path 705" d="M13.84,3.929,10.61.7a.518.518,0,0,0-.379-.16.586.586,0,0,0-.21.042.5.5,0,0,0-.328.5V2.692H8.346a13.747,13.747,0,0,0-1.653.093,8.918,8.918,0,0,0-1.342.257A5.462,5.462,0,0,0,4.3,3.45a4.481,4.481,0,0,0-.8.517,3.28,3.28,0,0,0-.576.623,3.863,3.863,0,0,0-.4.686,3.962,3.962,0,0,0-.236.74,5.8,5.8,0,0,0-.118.757q-.029.353-.029.766A4.028,4.028,0,0,0,2.3,8.565a6.7,6.7,0,0,0,.324.959q.177.4.417.837t.332.576q.093.143.177.261a.263.263,0,0,0,.219.109.456.456,0,0,0,.1-.017A.259.259,0,0,0,4.038,11q-.379-2.827.623-3.98.968-1.1,3.685-1.1H9.692V7.538a.5.5,0,0,0,.328.5.585.585,0,0,0,.21.042.5.5,0,0,0,.379-.16L13.84,4.686a.528.528,0,0,0,0-.757Z" fill="#fff"/>
      <path id="Path_706" data-name="Path 706" d="M11.669,8.615a.25.25,0,0,0-.294.067,1.773,1.773,0,0,1-.454.311.265.265,0,0,0-.151.244v1.8a1.35,1.35,0,0,1-1.346,1.346h-7a1.3,1.3,0,0,1-.951-.4,1.3,1.3,0,0,1-.4-.951v-7a1.3,1.3,0,0,1,.4-.951,1.3,1.3,0,0,1,.951-.4h.942A.389.389,0,0,0,3.5,2.659a5.617,5.617,0,0,1,1.119-.5.246.246,0,0,0,.219-.269.273.273,0,0,0-.269-.269H2.423a2.334,2.334,0,0,0-1.712.711A2.334,2.334,0,0,0,0,4.038v7a2.334,2.334,0,0,0,.711,1.712,2.334,2.334,0,0,0,1.712.711h7a2.427,2.427,0,0,0,2.423-2.423V8.859A.254.254,0,0,0,11.669,8.615Z" fill="#fff"/>
    </svg>

  )
};

export const SvgArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="5.021" height="8.904" viewBox="0 0 5.021 8.904">
      <path id="icon-up" d="M5621.7,231.783l3.021,3.261-3.021,3.643" transform="translate(-5620.697 -230.783)" fill="none" stroke="#de4547" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    </svg>
  )
};

export const SvgCross = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.129" height="17.129" viewBox="0 0 17.129 17.129">
      <g id="icon-delete" transform="translate(-343.416 -585.417)">
        <path id="Path_773" data-name="Path 773" d="M15.026,0,0,15.129" transform="translate(359.495 601.546) rotate(180)" fill="none" stroke="#9f9d9d" strokeLinecap="round" strokeWidth="2"/>
        <path id="Path_774" data-name="Path 774" d="M15.026,0,0,15.129" transform="translate(344.417 601.494) rotate(-90)" fill="none" stroke="#9f9d9d" strokeLinecap="round" strokeWidth="2"/>
      </g>
    </svg>
  )
};

export const SvgMail = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
      <rect id="Rectangle_205" data-name="Rectangle 205" width="14" height="14" transform="translate(981 530)" fill="#fff"/>
      <path id="Path_667" data-name="Path 667" d="M3.007,2.048a.885.885,0,0,1,.879-.9h6.266a.908.908,0,0,1,.907.9V6.39l1.149-.833V1.189A1.177,1.177,0,0,0,11.044,0H2.949A1.136,1.136,0,0,0,1.824,1.141V5.6l1.184.856V2.048Z" fill="#fff"/>
      <path id="Path_668" data-name="Path 668" d="M9.67,2.662h-5.2a.575.575,0,1,0,0,1.15h5.2a.575.575,0,1,0,0-1.15Z" fill="#fff"/>
      <path id="Path_669" data-name="Path 669" d="M9.67,4.568h-5.2a.575.575,0,1,0,0,1.151h5.2a.575.575,0,1,0,0-1.151Z" fill="#fff"/>
      <path id="Path_670" data-name="Path 670" d="M10.238,6.968a.561.561,0,0,0-.568-.46h-5.2a.566.566,0,0,0-.578.564.411.411,0,0,0,0,.046l.707.507H9.3Z" fill="#fff"/>
      <path id="Path_671" data-name="Path 671" d="M6.7,9.11a.436.436,0,0,0,.491,0L8.3,8.348H5.579Z" fill="#fff"/>
      <path id="Path_672" data-name="Path 672" d="M1.155,5.751A.3.3,0,0,0,.674,6v6.943a.384.384,0,0,0,.606.315L6.107,9.86a.3.3,0,0,0,.129-.246.3.3,0,0,0-.124-.246Z" fill="#fff"/>
      <path id="Path_673" data-name="Path 673" d="M1.7,13.6a.222.222,0,0,0,.128.4H11.9a.3.3,0,0,0,.171-.55L7.2,10.071a.4.4,0,0,0-.454,0Z" fill="#fff"/>
      <path id="Path_674" data-name="Path 674" d="M7.771,9.347a.3.3,0,0,0-.128.247.3.3,0,0,0,.131.246l5.077,3.511a.3.3,0,0,0,.475-.247V5.986a.3.3,0,0,0-.478-.247Z" fill="#fff"/>
    </svg>

  )
};