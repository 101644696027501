import React, {Component} from 'react'
import styled from 'styled-components'
import { prop,any,compose,values,map,equals } from 'ramda';

import FlexBlock from '../FlexBlock'
import { Text } from '../Typography'
import Input, { themeInputTiny } from '../Input'
import Textarea, { themeTextareaBordered } from "../Textarea";
import Button from "../Button";
import { ErrorInput } from "../Error";

const MailWrap = styled.div`
  
`;

const MailRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  textarea{
    width: 100%;
  }
`;

class Mail extends Component {
  static defaultProps = {
    sendTo: {
      val: '',
      err: false
    },
    message: {
      val: '',
      err: false
    }
  };

  state = {
    ...this.props
  };

  render() {
    const hasErrors = compose(any(equals(true)), values,map(prop('err')))(this.state);
    const hasEmpty = compose(any(equals('')), values, map(prop('val')))(this.state);
    
    return (
      <MailWrap>
        <MailRow>
          <FlexBlock flex="100px 0 0" align-items="flex-start">
            <Text>Кому:</Text>
          </FlexBlock>
          <FlexBlock flex="1">
            <Input theme={themeInputTiny}
                   setValue={this.setValue('sendTo')}
                   value={this.state.sendTo.val}
                   validationsList={[
                     [/^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/, 'Неверный email адрес']
                   ]}
                   renderError={msg => (
                     <ErrorInput position="0:relative|1000:absolute"
                                 left="0:0|1000:calc(100% + 25px)"
                                 text-align="0:center|1000:left"
                                 theme="themeErrorInputSmall"
                     >{msg}</ErrorInput>
                   )}/>
          </FlexBlock>
        </MailRow>
        <MailRow>
          <FlexBlock flex="100px 0 0" align-items="flex-start">
            <Text>Текст:</Text>
          </FlexBlock>
          <FlexBlock flex="1">
            <Textarea setValue={this.setValue('message')}
                      value={this.state.message.val}
                      flex="100% 0 0"
                      theme={themeTextareaBordered}/>
          </FlexBlock>
        </MailRow>
        <MailRow>
          <FlexBlock flex="100px 0 0" />
          <FlexBlock flex="1">
            <Button onClick={this.sendEmail}
                    disabled={hasErrors || hasEmpty}
                    dark>Отправить</Button>
          </FlexBlock>
        </MailRow>
      </MailWrap>
    )
  }

  setValue = name => (val,err) => {
    this.setState({
      [name]: {
        val, err
      }
    })
  };

  sendEmail = () => {
    //server
    // mail -> this.state.sendTo.val
    // message -> this.state.message.val
    this.setState({
      ...this.props
    })
  }
}

export default Mail