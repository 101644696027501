import React from 'react';

export const SvgHeadlineAdmin = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
      <path id="Path_748" data-name="Path 748" d="M93.666,85.333A8.333,8.333,0,1,0,102,93.666,8.343,8.343,0,0,0,93.666,85.333Zm0,14.583a6.25,6.25,0,1,1,6.25-6.25A6.257,6.257,0,0,1,93.666,99.916Z" transform="translate(-81.166 -81.166)" fill="#d32228"/>
      <path id="Path_749" data-name="Path 749" d="M12.5,0A12.5,12.5,0,1,0,25,12.5,12.514,12.514,0,0,0,12.5,0Zm0,21.875A9.375,9.375,0,1,1,21.875,12.5,9.386,9.386,0,0,1,12.5,21.875Z" fill="#d32228"/>
      <path id="Path_750" data-name="Path 750" d="M150.1,133.111a.521.521,0,0,0-.493-.354h-3.595l-1.116-3.612a.542.542,0,0,0-.995,0l-1.116,3.612h-3.595a.521.521,0,0,0-.316.935l2.931,2.238-1.123,3.632a.521.521,0,0,0,.814.568l2.9-2.218,2.9,2.218a.521.521,0,0,0,.814-.568l-1.123-3.632,2.931-2.238A.521.521,0,0,0,150.1,133.111Z" transform="translate(-131.894 -122.528)" fill="#d32228"/>
    </svg>
  )
}