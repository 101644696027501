import React from 'react'
import styled from 'styled-components'

const ActionButtonIconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #707070;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 18px;
`

function ActionButtonIcon(props){
  return (
    <ActionButtonIconWrap className='action-button__icon'>
      {props.children}
    </ActionButtonIconWrap>
  )
}

export default ActionButtonIcon