import React, {Component} from 'react'
import styled, {css, ThemeProvider} from 'styled-components'
import {Collapse} from 'react-collapse'

import { SvgPlus,SvgArrow } from '../SvgIcon'

const ExpansionPanelWrap = styled.div`
  z-index: 2;
  position: relative;
  margin-bottom: 30px;
  &:last-child{
    margin-bottom: 0;
  }
  
  ${props => props.theme.name === 'themeExpansionPanelRegAddNew' && css`
    margin-left: ${props.theme.ExpansionPanelWrap.marginLeft}
  `}
`;

const ExpansionPanelIcon = styled.div`
  width: 30px;
  height: 30px;
  border-width: ${props => props.opened ? '10px' : '1px'};
  border-style: solid;
  border-color: ${props => props.opened ? '#D32228' : '#9F9D9D'};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  
  ${props => props.theme.name === 'themeExpansionPanelArrow' && css`
    border-width: 1px;
    border-color: #9F9D9D !important;
    
    svg{
      transform: ${props => props.opened ? 'rotate(-90deg)' : 'rotate(90deg)'};
      path{
        stroke: #4A4A58;
      }
    }
  `}
  
  ${props => props.theme.name === 'themeExpansionPanelRegAddNew' && css`
    background-color: ${props.opened ? '#DE4547' : '#9F9D9D'};
    border: none;
  `}
  
  ${props => props.theme.name === 'themeExpansionPanelIcon' && css`
    background-color: ${props.opened ? '#DE4547' : '#9F9D9D'};
    border: none;
  `}
`;

const ExpansionPanelHeader = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  
  ${props => !props.opened && css`
    &:hover{
      ${ExpansionPanelIcon}{
        border: 2px solid #D32228;
      }
    }
  `}
`;

const ExpansionPanelText = styled.div`
  margin-left: 10px;
  color: #464646;
  font-weight: ${props => props.theme.ExpansionPanelText ? props.theme.ExpansionPanelText.fontWeight : 600};
`

const ExpansionPanelBody = styled.div`
  padding: 25px 0 0 15px;
  
  ${props => props.theme.name === 'themeExpansionPanelRegAddNew' && css`
    padding-left: ${props.theme.ExpansionPanelBody.paddingLeft};
  `}
  
  ${props => (props.theme.name === 'themeExpansionPanelRegAddNew' || props.theme.name === 'themeExpansionPanelOwnListDns') && css`
    ${props => props.opened && css`
      &:before{
        content: '';
        position: absolute;
        bottom: 20px;
        left: 15px;
        display: block;
        width: 200px;
        height: 1px;
        background-image: linear-gradient(to right, #DE4547 50%, rgba(255,255,255,0) 0%);
        background-size: 10px 1px;
        background-repeat: repeat-x;
      }
    `}
  `}
  
  ${props => props.theme.name === 'themeExpansionPanelIcon' && css`
    padding-left: 42px;
    ${props => props.opened && css`
      &:before{
        content: '';
        position: absolute;
        top: 8px;
        left: 15px;
        display: block;
        width: 1px;
        height: calc(100% - 28px);
        background-image: linear-gradient(to bottom, #DE4547 50%, rgba(255,255,255,0) 0%);
        background-size: 1px 10px;
        background-repeat: repeat-y;
      }
      &:after{
        content: '';
        position: absolute;
        bottom: 20px;
        left: 15px;
        display: block;
        width: 200px;
        height: 1px;
        background-image: linear-gradient(to right, #DE4547 50%, rgba(255,255,255,0) 0%);
        background-size: 10px 1px;
        background-repeat: repeat-x;
      }
    `}
    
    ${props => props.styles === 'themeExpansionPanelAddComment' && css`
      &:before, &:after{
        display: none;
      }
    `}
  `}
  
`

//
//THEME
//
export const themeExpansionPanelStandard = {
  name: 'themeExpansionPanelStandard'
};
export const themeExpansionPanelArrow = {
  name: 'themeExpansionPanelArrow',
  Icon: <SvgArrow/>
};
export const themeExpansionPanelOwnListDns = {
  name: 'themeExpansionPanelOwnListDns'
};
export const themeExpansionPanelRegAddNew = {
  ExpansionPanelText: {
    fontWeight: 400
  },
  ExpansionPanelWrap: {
    marginLeft: '-39px'
  },
  ExpansionPanelBody: {
    paddingLeft: '42px'
  },
  Icon: <SvgPlus/>,
  name: 'themeExpansionPanelRegAddNew'
};
export const themeExpansionPanelIcon = icon => ({
  ExpansionPanelText: {
    fontWeight: 400
  },
  Icon: icon,
  name: 'themeExpansionPanelIcon'
});


class ExpansionPanel extends Component {
  static defaultProps = {
    theme: themeExpansionPanelStandard
  };

  state = {
    show: this.props.show || false
  };

  componentWillReceiveProps(nextProps){
    if(!nextProps.show && nextProps.show !== undefined){
      this.setState({
        show: nextProps.show
      })
    }
  }

  render() {
    return (
      <ThemeProvider theme={this.props.theme}>
        <ExpansionPanelWrap {...this.props}>
          <ExpansionPanelHeader onClick={this.showExpansion} opened={this.state.show}>
            <ExpansionPanelIcon opened={this.state.show}>
              {this.props.theme.Icon}
            </ExpansionPanelIcon>
            <ExpansionPanelText>{this.props.title}</ExpansionPanelText>
          </ExpansionPanelHeader>

          <Collapse isOpened={this.state.show} hasNestedCollapse={true}>
            <ExpansionPanelBody opened={this.state.show} styles={this.props.styles}>{this.props.children}</ExpansionPanelBody>
          </Collapse>
        </ExpansionPanelWrap>
      </ThemeProvider>
    )
  }

  showExpansion = (e) => {
    this.setState({
      show: !this.state.show
    })
  }
}

export default ExpansionPanel