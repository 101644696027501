function parseMediaKey(key){
  switch(key){
    case 'xs': return 1;
    case 'sm': return 426;
    case 'md': return 769;
    case 'lg': return 1025;
    case 'xl': return 1201;
    default: return parseInt(key);
  }
}

function mediaBreakpoint(number, val){
  if(number === 0) return `${val}\n`;
  return `@media (min-width: ${number}px){ ${val} }\n`
}

const responsiveProps = respPropsList => props => {
  let result = '';

  respPropsList.forEach(propName => {
    if (props.hasOwnProperty(propName)) {
      if (props[propName].indexOf(':') !== -1) {
        result += props[propName]
          .split('|')
          .map(el => el.split(':'))
          .map(([key, val]) => mediaBreakpoint(parseMediaKey(key), `${propName}: ${val} `))
          .join('')
      } else {
        result += `${propName}: ${props[propName]};\n`
      }
    }
  });
  return result
};

export default responsiveProps;