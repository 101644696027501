import React, {Component} from 'react'
import styled, { ThemeProvider, css } from 'styled-components'
import InputMask from 'react-input-mask'

import Button from '../Button'

import { SvgDelete } from "../SvgIcon";

import responsiveProps from '../../../utils/responsiveProps';

const InputWrap = styled.div`
  position: relative;
  flex: 360px 0 0;
  
  ${Button}{
    position: absolute;
    line-height: 0;
    top: calc(50% - 3px);
    right: -20px;
  }
  
  .input{
    width: 100%;
    padding: 10px 20px 12px;
    border-width: 1px;
    border-style: solid;
    border-color: ${props => !!props.errorMsg.length ? '#D32228' : '#CBCBCB'}
    outline: none;
    background-color: inherit;
    &::placeholder{
      color: #CBCBCB;
    }
    &:focus{
      border-color: ${props => !!props.errorMsg.length ? '#D32228' : '#464646'};
    }
    &:disabled{
      background-color: inherit;
      border: none;
    }
    
    ${responsiveProps(['padding'])} 
  }
  
  ${props => props.theme.name === 'themeInputTiny' && css`
    ${Button}{
      display: none;
    }
    .input{
      border: none;
      border-bottom: 1px solid #9F9D9D;
      padding: 0 14px 0 4px;
      outline: none;
      &:disabled{
        background-color: inherit;
        border: none;
      }
    }
  `}
  
  ${responsiveProps(['flex'])} 
`;

//THEME
export const themeInputStandard = {
  name: 'themeInputStandard'
}
export const themeInputTiny = {
  name: 'themeInputTiny'
}


export default class Input extends Component {
  static defaultProps = {
    theme: themeInputStandard
  };

  state = {
    errorMsg: [],
    focus: false
  };

  render() {
    return (
      <ThemeProvider theme={this.props.theme}>
        <InputWrap {...this.state} {...this.props}>
          <InputMask mask={this.props.mask}
                     placeholder={this.props.placeholder}
                     onChange={this.onChangeValue}
                     onFocus={() => this.setState({focus: true})}
                     onBlur={() => this.setState({focus: false})}
                     value={this.props.value}
                     className="input"
                     disabled={this.props.disabled}
                     padding={this.props.padding}
          />
          {!!this.props.value.length && !this.props.hideButton && <Button className="tiny" onClick={() => {this.props.setValue('')}}>
            <SvgDelete/>
          </Button>}
          {!!this.props.value.length && this.state.focus && this.props.tip}
          {this.props.renderError && !!this.state.errorMsg.length && !this.state.focus && this.props.renderError(this.state.errorMsg[0])}
        </InputWrap>
      </ThemeProvider>
    )
  }
  
  onChangeValue = e => {
    const errorMsg = (this.props.validationsList || []).map(([pred, msg]) => {
      if (e.target.value === '') return '';
      if(typeof pred === "function"){
        return pred(e.target.value) ? '' : msg;
      }
      return pred.test(e.target.value) ? '' : msg;
    }).filter(Boolean);
    this.setState({errorMsg});
    return this.props.setValue(e.target.value, !!errorMsg.length)
  }
}